@import 'style';

.authors + .tags,
.authors + .more,
.authors + .date,
.authors + .contactpersons,
.contactpersons + .tags,
.contactpersons + .more,
.contactpersons + .date,
.more + .tags,
.more + .date,
.tags + .date {
	margin-top: 35 * $px;
}

.sidebar {
	position: relative;
}

.authors {
	&-item {
		&-image {
			position: relative;
			display: inline-block;
			vertical-align: top;
			max-width: 460px;
			background: $gradient-diagonal;
		}

		&-top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 15 * $px;

			&-info {
				flex: 1 1 auto;
				padding-right: 30 * $px;
			}
		}

		&-pname,
		&-function {
			font-size: 16 * $px;
			line-height: 1.5;
		}

		&-pname {
			font-weight: 700;
		}

		&-description {
			font-size: 14 * $px;
			line-height: 1.5;
			margin-top: 15 * $px;
		}

		& + & {
			margin-top: 35 * $px;
		}

		&-toggle {
			position: relative;

			&-icon {
				color: #979797;
				font-size: 8 * $px;
				display: inline-block;
				vertical-align: middle;
				transition: transform 0.25s ease-out;

				.is-open & {
					transform: rotate(-180deg);
				}
			}
		}
	}
}

.contactpersons {
	&-item {
		&-pname,
		&-function {
			font-size: 16 * $px;
			line-height: 1.5;
		}

		&-pname {
			font-weight: 700;
		}

		&-contact {
			display: flex;
			align-items: center;
			margin-top: 10 * $px;

			&-icon {
				color: $dark-grey;
				transition: color 0.25s ease-out;

				&:hover {
					color: $blue;
				}

				&.mod-mobile {
					width: 12px;
				}

				&.mod-phone {
					width: 20px;
				}

				&.mod-email {
					width: 25px;
				}

				& + & {
					margin-left: 15 * $px;
				}
			}
		}

		& + & {
			margin-top: 35 * $px;
		}
	}
}

.tags {
	&-label {
		line-height: 1.5;
		font-weight: 700;
	}

	&-items {
		margin-left: -10 * $px;

		&-item {
			display: inline-block;
			vertical-align: top;
			margin-top: 10 * $px;
			padding-left: 10 * $px;

			&-tag {
				display: block;
				color: white;
				font-size: 12 * $px;
				line-height: 1.5;
				padding: 7 * $px 20 * $px;
				background-color: $dark-grey;
				border-radius: 50 * $px;
				border: 1px solid $dark-grey;
				transition: all 0.25s ease-out;

				&:hover {
					color: $text;
					background-color: transparent;
				}
			}
		}
	}
}

.date {
	&-label {
		font-weight: 700;
	}
}
