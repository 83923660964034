@import 'style';

.article {
	&-contentsidebar {
		background-color: white;
		padding-top: 65 * $px;
	}

	&-bottom {
		background-color: white;
	}

	&-scroll {
		position: absolute;
		left: 0;
		bottom: 30 * $px;
		width: 100%;
		text-align: center;

		@include breakpoint('ipad-port') {
			bottom: 60 * $px;
		}
	}
}

.hero {
	position: relative;
	background-color: $dark-grey;
	padding: 80 * $px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;

	&.mod-image {
		padding: 150 * $px 0;

		@include breakpoint('ipad-land') {
			min-height: 560 * $px;
		}
	}

	.mod-preview & {
		min-height: inherit;
	}

	&-row {
		@include breakpoint('ipad-port') {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
		}
	}

	&-content {
		color: white;
		position: relative;

		@include breakpoint('ipad-port') {
			@include gridMaxWidth(8, 7);
		}

		@include breakpoint('ipad-land') {
			@include gridMaxWidth(7, 6);
		}

		&-subtitle {
			font-size: 15 * $px;
			margin-bottom: 5 * $px;
		}

		&-title {
			font-weight: 300;
		}

		&-description {
			font-size: 20 * $px;
			line-height: 1.5;
			margin-top: 15 * $px;
			@include m-lineclamp(5, 28 * $px);
		}
	}

	&-summary {
		color: white;
		font-size: 15 * $px;
		line-height: 1.5;
		font-weight: 500;
		position: relative;
		min-width: 250 * $px;
		padding: 30 * $px 20 * $px;
		margin-top: 100 * $px;
		background-color: rgba($dark-grey, 0.9);

		@include breakpoint('ipad-port') {
			margin-top: 0;
			@include gridMaxWidth(4, 3);
		}

		@include breakpoint('ipad-land') {
			@include gridMaxWidth(3, 2);
		}

		&-title {
			background-color: rgba($dark-grey, 0.9);
			font-size: 13 * $px;
			padding: 15 * $px;
			white-space: nowrap;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateY(-100%);

			@include breakpoint('ipad-port') {
				left: auto;
				right: 100%;
				transform-origin: right top;
				transform: rotate(-90deg) translateY(-100%);
			}
		}

		ul {
			list-style: none;
		}

		p,
		li {
			&:before {
				content: '';
				width: 30px;
				height: 4px;
				display: block;
				background-color: $blue;
				margin-bottom: 10 * $px;
			}
		}

		p + p,
		li + li {
			margin-top: 25 * $px;
		}
	}
}

.herotop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 25 * $px;

	&-left {
		&-cta {
			display: inline-flex;
			align-items: center;
			transition: all 0.25s ease-out;

			&-text {
				color: white;
				text-transform: uppercase;
				margin-left: 10 * $px;
			}

			&-circle {
				width: 30 * $px;
				height: 30 * $px;
				border-radius: 100%;
				background-color: $blue;
				display: flex;
				align-items: center;
				justify-content: center;

				&-icon {
					color: white;
					font-size: 12 * $px;
				}
			}

			&:hover {
				transform: translateX(-5 * $px);
			}
		}
	}

	&-center {
		&-logo {
			line-height: 1;
			display: inline-block;
			vertical-align: middle;

			&-img {
				height: 22 * $px;
			}
		}
	}

	&-right {
		position: relative;
	}
}
